<template>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane :label="item.name" :name="item.code" v-for="item in pondList" v-if="item.id > 0">
            <MyCluePage v-if="activeName === item.code" :pond-name="item.name" :pond-id="item.id"></MyCluePage>
        </el-tab-pane>
    </el-tabs>
</template>

<script>

import MyCluePage from "@/views/clue/component/MyCluePage.vue"
import * as clueApi from '@/api/clue/cluePond'

export default {
    name: 'MyClue',
    components: {MyCluePage},
    data() {
        return {
            activeName: "1",
            pondList: [],
        }
    },
    methods: {
        findPondList() {
            clueApi.findCluePond().then(res => {
                if (res.success) {
                    this.pondList = res.data
                    if (this.pondList) {
                        this.activeName = this.pondList[0].code
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        handleClick() {

        },
    },
    computed: {},
    filters: {},
    mounted() {
        this.findPondList()
    },
}

</script>

<style scoped lang="less">

.top-container {
  background: #FFFFFF;
}

/deep/ .el-drawer__body {
  overflow: auto;
  /* overflow-x: auto; */
}

/deep/ .el-drawer /deep/ .rtl {
  overflow: scroll
}

.function-button {
  height: 62px;
  display: flex;
  align-items: center;
}

.clearStream {
  margin: 0 15px 0 0 !important;
  background-color: #fb9337;
  border: none;
  border-radius: 4px !important;
  width: 132px;
  height: 50px;
  font-size: 16px;
  margin-top: 5px !important;
}

.el-table th > .cell {
  display: inline-block;
  white-space: nowrap;
  word-break: keep-all;
  text-overflow: unset;
}

/deep/ .telephone {
  cursor: pointer;
}

/deep/ .order-success {
  background: url("../../assets/icon/order_success.png") no-repeat left 0px top 0px;
  background-size: 40px;
  background-color: #FFFFFF;
}

/deep/ .oppor-waken {
  background: url("../../assets/icon/oppor_waken.png") no-repeat left 0px top 0px;
  background-size: 40px;
  background-color: #FFFFFF;
}

/deep/ .el-table__body tr.hover-row > td {
  background-color: transparent !important;
}


.red {
  color: red;
}

.green {
  color: green;
}

.position {
  position: relative;
}

.positionBottom {
  display: inline-block;
  position: absolute;
  bottom: -2px;
  cursor: pointer;
}

/deep/ .el-button--success {
  color: #FFF;
  background-color: #2f8e00;
  border-color: #2f8e00;
}

.WeChatIcon {
  color: #43C420;
  font-weight: bolder;
  font-size: 18px;
  vertical-align: middle;
}

.item {
  margin-top: 10px;
  margin-right: 30px;
}


.top-tool-btn-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: row;
    max-width: 50vw;
    padding-left: 5px;
  }

  .right {
    display: flex;
    flex-direction: row;

    ._btn-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      height: fit-content;


      :nth-child(n) {
        margin-bottom: 10px !important;
      }
    }

  }

}

</style>
